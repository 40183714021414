header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}
.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.typed-block {
  /* background-color: red; */
  height: 30px;
}

.sd-2 {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* ========== MOUSE WHEEL ========== */
.mouse {
  position: absolute;
  border: 2px solid var(--color-primary);
  display: block;
  height: 1.6rem;
  width: 1.25rem;
  margin: auto;
  border-radius: 1rem;
  position: relative;
  transform: rotate(90deg);
  margin-left: auto;
  margin-right: auto;
  /* right: -40.2rem;
  bottom: -12rem; */
}

@keyframes ani-mouse {
  0% {
    top: 29%;
  }

  15% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

.wheel {
  background-color: var(--color-primary);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%);
  animation: ani-mouse 2s linear infinite;
}

/* ============= CTA ============= */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/* ============= HEADER SOCIALS ============= */
.header__socials {
  /* margin-top: 2.5rem; */
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  /* position: absolute; */
  /* left: 0; */
  /* bottom: 3rem; */
  position: relative;
  bottom: auto;
  margin-top: 0;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ============= ME ============= */
.me {
  /* background: linear-gradient(var(--color-primary), transparent); */
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  /* border-radius: 12rem 12rem 0 0; */
  border-radius: 0%;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* =============  SCROLL DOWN ============= */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 7rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ========== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
